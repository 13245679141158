<template>
    <div class="presentation-video">
        <video id="video-presentation-page" controls>
            <source
                v-if="isBuySell"
                :src="`https://w3d4e4y6bt9n62nh8v75.s3.eu-west-3.amazonaws.com/presentation_${returnLocale}.mp4`"
                type="video/mp4"
            />
            <source
                v-if="isBlocktrade"
                :src="`https://w3d4e4y6bt9n62nh8v75.s3.eu-west-3.amazonaws.com/blocktrade_${returnLocale}.mp4`"
                type="video/mp4"
            />
        </video>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  
  export default {
    name: 'videos',
    components: {},
    data() {
      return {
      }
    },
    async mounted() {
        const video = document.getElementById('video-presentation-page')
          video.addEventListener('loadedmetadata', function () {
            video.currentTime = 1
          })
    },
    computed: {
      ...mapGetters({
      }),
      returnLocale() {
            // return this.$i18n.locale;
            return 'en-GB'
        },
        isBuySell() {
            if (this.$route.query.trading) {
                return true;
            }
            return false;
        },
        isBlocktrade() {
            if (this.$route.query.blocktrade) {
                return true;
            }
            return false;
        }
    },
    methods: {
      ...mapActions({
      }),
    }
  }
</script>
  
<style scoped lang="scss">
  @import '@/assets/styles/root.scss';

  .presentation-video {
    video {
        width: 100%;
    }
  }
  
</style>
  